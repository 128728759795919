<template>
  <div class="parent">
    <img class="logo" src="@/assets/logo.png" />
    <div class="videoParent">
      <video ref="myVideo" class="myVideo" controls="false" playsinline autoplay>
        <source :src="videoUrl" type="video/mp4">
      </video>
      <img v-if="isShowCoverImg" :class="videoBG" :src="coverImgSrc" />
      <img v-if="isShowPlayImg" :class="playIconControl" @click="videoPlay" src="@/assets/icon_play.png" />

    </div>
    <span class="title">{{ title }}</span>
    <span class="intro">{{ intro }}</span>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getData } from '@/http/courseDetail'
const myVideo = ref()
const playIconControl = ref('playIcon')
const videoBG = ref('videoBG')

const videoUrl = ref('')
const title = ref('')
const intro = ref('')
const coverImgSrc = ref('')
const isShowCoverImg = ref(true)
const isShowPlayImg = ref(true)


function videoPlay() {
  console.log(myVideo.value)
  myVideo.value.controls = true
  myVideo.value.load()
  myVideo.value.play()
  isShowPlayImg.value = false
  isShowCoverImg.value = false

}
onMounted(() => {
  myVideo.value.controls = false
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id'); // 替换paramName为实际参数名
  if (id != null) {
    //109586
    getData(id).then(res => {
      console.log(res.data.data[0])
      const item = res.data.data[0];
      if (item != null) {
        videoUrl.value = item.videoPlayurl
        title.value = item.sourceName
        intro.value = item.briefIntro
        coverImgSrc.value = "https://resource.home121.net/" + item.coverImgSrc
        console.log("封面图：" + coverImgSrc.value)
      }

    }).catch(err => {
      console.log('err:' + err)
    })
  }

})


</script>

<style lang="less">
#app {
  padding: 0;
  margin: 0;
}

.parent {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;

  .logo {
    width: 248px;
    margin-left: 38px;
    margin-top: 134px;
  }



  .title {
    color: white;
    font-size: 35px;
    margin-top: 72px;
    margin-left: 38px;
    margin-right: 38px;

  }

  .intro {
    color: white;
    font-size: 26px;
    margin-top: 66px;
    margin-left: 38px;
    margin-right: 38px;
  }

  .videoParent {
    position: relative;
    margin-top: 118px;

    .myVideo {
      width: 750px;
    }

    .playIcon {
      width: 128px;
      height: 128px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .videoBG {
      width: 750px;
      height: 400px;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .dis {
    display: none;
  }

  // video::-webkit-media-controls {
  //   display: none !important;
  // }


}
</style>
